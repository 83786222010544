<template>
    <div class="register-box">
  <div class="card">
    <div class="card-body register-card-body auth-box">
      <h3 class="login-box-msg">Registrasi akun TARUNA</h3>
      <div :class="alertStatus" v-if="showMessage">{{ textMessage }}</div>
      <form @submit="submitForm" method="post">
        <div class="input-group">
          <input type="text" v-model="form.name" ref="name" required minlength="4" pattern="([A-z\s]){2,}" class="form-control" placeholder="Nama Lengkap">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div class="input-group mt-3">
          <input type="email" class="form-control" ref="email" required v-model="form.email" placeholder="Email">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mt-3">
          <input type="number" class="form-control" ref="tel" required v-model="form.tel" onKeyPress="if(this.value.length==20) return false;" placeholder="contoh: 081234567890">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-phone"></span>
            </div>
          </div>
        </div>
        <div class="input-group mt-3">
          <input type="password" class="form-control" ref="pass" minlength="8" required v-model="form.pass" placeholder="Sandi">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="input-group mt-3">
          <input type="password" class="form-control" ref="pass2" required v-model="form.pass2" placeholder="Ulangi Sandi">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="false">
          <div class="col-6">
          <input type="text" class="form-control" ref="captcha" pattern="[A-Za-z0-9]{1,6}" required v-model="form.captcha" placeholder="Masukkan kode">
        </div>
          <div class="col-6"><img ref="imgc" :src="captchaUrl" class="mr-2" /> <button type="button" title="Reload" class="btn btn-sm btn-secondary" v-on:click="reloadCaptcha"><i class="fa fa-redo"></i></button></div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div>
                                <div class="icheck-material-orange icheck-inline">
    <input type="checkbox" required id="chb1" />
    <label for="chb1" style="font-weight:normal;">Setuju dengan <a v-on:click="showTerm"><strong>Ketentuan dan Kebijakan Privasi</strong></a></label>
</div>
</div>
          </div>
</div>
          <!-- /.col -->
          <div class="row mt-3 mb-3">
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn-block" :disabled="loading" ><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Registrasi...":"Registrasi" }}</button>
          </div>
          <!-- /.col -->
        </div>
      </form>

      <div class="social-auth-links text-center d-none">
        <p>- ATAU -</p>
        <a href="#" class="btn btn-block btn-danger" disabled>
          <i class="fab fa-google-plus mr-2"></i>
          Daftar Melalui Google
        </a>
      </div>

      <div class="text-center"><router-link to="/login" class="text-center">Sudah memiliki akun</router-link></div>
    </div>
    <!-- /.form-box -->
  </div><!-- /.card -->
  <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">Ketentuan & Kebijakan Privasi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="height: 35vh; overflow-y: auto;">
                            <strong>Terms & Conditions</strong>
                            <ol class="terms" v-if="false">
                              <li>BosCOD merupakan platform multi kurir COD dan NonCOD</li>
                              <li>BosCOD mengedepankan sukses delivery dan zero return without confirmation</li>
                              <li>BosCOD tidak memberikan jaminan nilai/angka retur</li>
                              <li>Segala bentuk pembayaran akan dilakukan setelah paket sukses dengan periode 1-7 hari setelah sukses</li>
                              <li>Apabila dikemudian hari ada kendala terhadap angka atau kasus return with confirmation atau return without confirmation, maka akan diselesaikan dengan mediasi BosCOD dan dianggap keputusan Final.</li>
                            </ol>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</div>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';

import $ from 'jquery';
import "icheck-material";

export default {
    name: 'Registrasi',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            formTitle: 'Registrasi',
            showMessage: false,
            loading: false,
            textMessage: '',
            alertStatus: '',
            captchaLink: '/users/register/captcha',
            form: {
                name: '',
                email: '',
                tel: '',
                pass: '',
                pass2: '',
                agree: '',
            }
        }
    },
    computed: {
    },
    created: function() {
        $('body').addClass('register-page');
        $('body').removeAttr('style');
        this.captchaUrl = window.$apiUrl + this.captchaLink;
    },
    mounted() {
        
    },
    beforeDestroy() {
        $('body').removeClass('register-page');
    },
    methods: {
      showTerm: function()
      {
            const e = this.$refs;
            $(e.formDialog).modal("show");
      },
      resetForm: function()
      {
        for (const key in this.$refs) {
          this.$refs[key].setAttribute('title', '');
          this.$refs[key].classList.remove('is-invalid');
        }
      },
      clearForm: function()
      {
            this.form = {
                name: '',
                email: '',
                tel: '',
                pass: '',
                pass2: '',
                agree: '',
            };
      },
        submitForm: function(e) {
          this.resetForm();
          this.showMessage = false;
          this.loading = true;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            authFetch('/users/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data
            })
            .then(res => {
              this.loading = false;
                if (res.status === 201) {
                } else if (res.status === 400) {
                    //let errorResponse = await res.json();
                    //this.errors.push(errorResponse.error);
                }
                return res.json();
            })
            .then(js => {
              if(js.status){
                this.textMessage = js.message;//'Registrasi berhasil. Silahkan periksa email untuk verifikasi akun.';
                this.showMessage = true;
                this.clearForm();
                  this.alertStatus = 'alert alert-success';
              }
              else if(!js.status)
              {
                if(js.type=='error_input')
                {
                  for (const key in js.details) {
                    if(this.$refs[key])
                    {
                      this.$refs[key].setAttribute('title', js.details[key]);
                      this.$refs[key].classList.add('is-invalid');
                    }
                    
                  }
                }
                else{
                  this.textMessage = js.message;
                  this.showMessage = true;
                  this.alertStatus = 'alert alert-danger';
                }
              }
                console.log(js)
            });
            e.preventDefault();
        },
        reloadCaptcha: function()
        {
          this.$refs.imgc.src = this.captchaUrl;
        }
    }
}
</script>
<style scoped>
  .terms li{line-height:2;}
  </style>